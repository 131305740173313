import gql from "graphql-tag";

export const checkPromoCodeGql = gql`
  query CheckPromoCode($input: CheckPromoCodeInput!) {
    checkPromoCode(input: $input) {
      ... on PromoCode {
        __typename
        id
        code
        expiry
        isActive
        durationInMonths
        discountPerVideo
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          displayMessage
          message
        }
      }
    }
  }
`;
