import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-4" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "flex flex-col mb-4" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex justify-center gap-4 mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormGroup, {
      model: _ctx.formState,
      onFinish: _ctx.handleValidForm
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_InputText, {
              value: _ctx.formState.code,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.code) = $event)),
              name: "code",
              placeholder: _ctx.t('Enter code'),
              disabled: !!_ctx.currentPromoCode || _ctx.disabled
            }, null, 8, ["value", "placeholder", "disabled"])
          ]),
          _createVNode(_component_a_button, {
            type: !_ctx.currentPromoCode ? 'primary' : 'secondary',
            "html-type": "submit",
            loading: _ctx.checkPromoCodeLoading || _ctx.loading,
            disabled: !_ctx.formState.code || _ctx.disabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(!_ctx.currentPromoCode ? _ctx.t("Apply") : _ctx.t("Remove")), 1)
            ]),
            _: 1
          }, 8, ["type", "loading", "disabled"])
        ])
      ]),
      _: 1
    }, 8, ["model", "onFinish"]),
    _createVNode(_component_a_modal, {
      width: "530px",
      visible: _ctx.isConfirmModalVisible,
      footer: null,
      onCancel: _ctx.handleCancel,
      "mask-closable": "",
      centered: "",
      class: "confirm-promo-modal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_LayoutIcon, { name: "tickets" }),
          _createVNode(_component_a_typography_title, {
            level: 5,
            class: "my-6"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Use the Promo Code?")), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("If you use the Promo Code, there will be adjustments such as:")), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.terms, (term) => {
            return (_openBlock(), _createBlock(_component_IconLabel, {
              text: term,
              key: term
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_AppIcon, { name: "checkmarkGreen" })
              ]),
              _: 2
            }, 1032, ["text"]))
          }), 128))
        ]),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createVNode(_component_i18n_t, { keypath: "Read more of our {terms} to know more." }, {
              terms: _withCtx(() => [
                _createElementVNode("a", {
                  href: _ctx.config.termsWebsiteUseUrl,
                  target: "_blank",
                  class: "underline"
                }, _toDisplayString(_ctx.t("Terms and Condition")), 9, _hoisted_5)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("By confirming, you agree to our terms.")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
          "If you want faster delivery time or any other customizations while using a promo code, please write us an email."
        )), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_a_button, {
            type: "secondary",
            onClick: _ctx.handleCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.handleConfirmCode
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onCancel"])
  ], 64))
}