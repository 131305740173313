
import {
  defineComponent,
  PropType,
  ref,
  watchEffect,
  computed,
  reactive,
} from "vue";
import { useI18n } from "vue-i18n";
import { useCheckPromoCode } from "@/shared/composables/useCheckPromoCode";
import { CheckPromoCode_checkPromoCode_PromoCode } from "@/api/quotations/__generated__/CheckPromoCode";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import IconLabel from "@/shared/components/IconLabel.vue";
import LayoutIcon from "@/shared/components/Icons/LayoutIcon.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import { config } from "@/shared/utils/config";
import { getPromoCodeTerms } from "@/shared/utils/termsAndConditions";

export default defineComponent({
  props: {
    currentPromoCode:
      Object as PropType<CheckPromoCode_checkPromoCode_PromoCode | null>,
    loading: Boolean,
    disabled: Boolean,
  },
  emits: ["on-update"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formState = reactive({ code: "" });

    /** Only mutate this value when Promo Code is valid and confirmed */
    const shownPromoCode = ref<CheckPromoCode_checkPromoCode_PromoCode | null>(
      null
    );
    const isConfirmModalVisible = ref(false);

    // === Queries ===
    const { checkPromoCode, checkPromoCodeLoading } = useCheckPromoCode({});

    // === Handlers ===
    const handleValidForm = async () => {
      if (!props.currentPromoCode) {
        const response = await checkPromoCode({ code: formState.code });

        if (!response || "errors" in response) {
          return t("Something went wrong.");
        }

        // No errors encountered
        shownPromoCode.value = response;
        isConfirmModalVisible.value = true;
      } else {
        handleRemoveCode();
      }
    };

    const handleConfirmCode = () => {
      isConfirmModalVisible.value = false;

      emit("on-update", shownPromoCode.value);
    };

    const handleRemoveCode = () => {
      shownPromoCode.value = null;
      formState.code = "";

      isConfirmModalVisible.value = false;

      emit("on-update", shownPromoCode.value);
    };

    const handleCancel = () => {
      formState.code = "";
      isConfirmModalVisible.value = false;
    };

    const terms = computed(() => getPromoCodeTerms(shownPromoCode.value));

    watchEffect(() => {
      // Update internal value when external value changes
      if (props.currentPromoCode) {
        formState.code = props.currentPromoCode.code;
      }
    });

    return {
      t,
      config,
      formState,
      isConfirmModalVisible,
      handleValidForm,
      handleConfirmCode,
      handleRemoveCode,
      handleCancel,
      shownPromoCode,
      checkPromoCodeLoading,
      terms,
    };
  },
  components: {
    InputText,
    IconLabel,
    LayoutIcon,
    AppIcon,
    FormGroup,
  },
});
