
import { defineComponent, PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useQuotationPrices } from "@/shared/composables/useQuotationPrices";
import { userQuotationAddOnsList } from "@/shared/composables/useQuotationOptions";
import Card from "@/shared/components/Card.vue";
import QuotationPromoCode from "@/shared/components/QuotationPromoCode.vue";
import DiscountedPrice from "@/shared/components/DiscountedPrice.vue";
import {
  testimonialTermsFullList,
  getPromoCodeTerms,
  generalTermsList,
} from "@/shared/utils/termsAndConditions";
import routeNames from "@/web/router/routeNames";
import { useRouter } from "vue-router";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { config } from "@/shared/utils/config";
import { Quotation_quotation_Quotation } from "@/api/quotations/__generated__/Quotation";

export default defineComponent({
  emits: ["on-update-promo-code"],
  props: {
    quotation: {
      type: Object as PropType<Quotation_quotation_Quotation | null>,
      required: true,
    },
    hideOwner: Boolean,
    loading: Boolean,
    promoCodeLoading: Boolean,
    promoCodeDisabled: Boolean,
  },
  setup(props) {
    const { t, n } = useI18n();
    const router = useRouter();
    const { redirectToOrderDetails } = useRedirectHelper();

    const quotationRef = computed(() => props.quotation);

    const { getQuotationPrice, quotationPricesLoading } = useQuotationPrices({
      quotationRef,
    });

    const { getFilteredAddOnsList } = userQuotationAddOnsList({ quotationRef });

    const filteredAddOnsList = computed(() =>
      getFilteredAddOnsList(props.quotation)
    );

    const numberOfVideos = computed(() => props.quotation?.numOfVideos ?? 0);
    const discountFromTotal = computed(() =>
      Number(props.quotation?.promoCode?.discountFromTotal ?? 0)
    );
    const discountPerVideo = computed(() => {
      if (discountFromTotal.value) {
        return 0;
      }

      return Number(props.quotation?.promoCode?.discountPerVideo ?? 0);
    });

    // === For PER VIDEO ===
    const originalPricePerVideo = computed(() =>
      getQuotationPrice("ORDER_VIDEO_BASE_PRICE")
    );
    const conditionalBasePricePerVideo = computed(() =>
      props.quotation?.useTestimonialPackage
        ? getQuotationPrice("ORDER_VIDEO_BASE_TESTIMONIAL_PRICE")
        : originalPricePerVideo.value
    );

    const discountedPricePerVideo = computed(
      () => conditionalBasePricePerVideo.value - discountPerVideo.value
    );

    // === For ALL VIDEOS ===
    const totalOriginalPriceAllVideosNoAddOns = computed(
      () => originalPricePerVideo.value * numberOfVideos.value
    );
    const totalPriceAllVideosNoAddOns = computed(
      () => discountedPricePerVideo.value * numberOfVideos.value
    );

    // For TOTAL
    const totalPrice = computed(() => Number(props.quotation?.totalPrice ?? 0));
    const totalPriceWithoutDiscount = computed(() => {
      if (discountFromTotal.value) {
        return totalPrice.value + discountFromTotal.value;
      }

      const totalDiscountFromPromo =
        discountPerVideo.value * numberOfVideos.value;
      const totalDiscountFromTestimonial =
        (originalPricePerVideo.value - conditionalBasePricePerVideo.value) *
        numberOfVideos.value;

      return (
        totalPrice.value +
        (totalDiscountFromPromo + totalDiscountFromTestimonial)
      );
    });

    const hasDiscount = computed(
      () => totalPrice.value < totalPriceWithoutDiscount.value
    );

    const hasPromo = computed(() => !!props.quotation?.promoCode);

    const showTermTitles = computed(() => hasPromo.value);

    const redirectToOrderDetail = () => {
      if (!props.quotation?.order?.id) {
        return;
      }

      redirectToOrderDetails({
        orderId: props.quotation.order.id,
      });
    };

    const additionalNotesList = [
      {
        key: "hireStrategist",
        title: t("You Hired a Writer"),
        content: t(
          "Since you requested for a {addOn}, we will be reaching out to you for your custom video ideas. You may also reach us out via {email}.",
          {
            addOn: `<strong>${t("Content Writer")}</strong>`,
            email: `<a href="mailto:${config.merchantSupportEmailUrl}">${config.merchantSupportEmailUrl}</a>`,
          }
        ),
      },
    ];

    const additionalNotes = computed(() =>
      additionalNotesList.filter((addOn) => props.quotation?.[addOn.key])
    );

    const redirectToDashboard = () => {
      router.push({ name: routeNames.profile });
    };

    const redirectToCart = () => {
      router.push({ name: routeNames.cart });
    };

    const mainButtonProperties = computed(() => ({
      handler: props.quotation?.hireStrategist
        ? redirectToDashboard
        : redirectToCart,
      label: props.quotation?.hireStrategist
        ? t("Go to Dashboard")
        : t("Proceed to Ordering Videos"),
    }));

    return {
      t,
      n,
      filteredAddOnsList,
      originalPricePerVideo,
      discountedPricePerVideo,
      totalOriginalPriceAllVideosNoAddOns,
      totalPriceAllVideosNoAddOns,
      totalPrice,
      totalPriceWithoutDiscount,
      hasDiscount,
      quotationPricesLoading,
      generalTermsList,
      hasPromo,
      testimonialTermsFullList,
      showTermTitles,
      additionalNotes,
      promoCodeTerms: computed(() =>
        getPromoCodeTerms(props.quotation?.promoCode)
      ),
      // Handlers
      redirectToOrderDetail,
      mainButtonProperties,
    };
  },
  components: {
    Card,
    QuotationPromoCode,
    DiscountedPrice,
  },
});
