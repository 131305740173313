import { CheckPromoCodeInput } from "@/../__generated__/globalTypes";
import { checkPromoCodeGql } from "@/api/quotations/checkPromoCode";
import {
  CheckPromoCode,
  CheckPromoCodeVariables,
  CheckPromoCode_checkPromoCode_PromoCode,
} from "@/api/quotations/__generated__/CheckPromoCode";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { HideIfErrorCode } from "../utils/graphql/errorHandler";

export const useCheckPromoCode = (options: { hideErrors?: boolean }) => {
  const { loading: checkPromoCodeLoading, refetch: refetchCheckPromoCode } =
    useQuery<CheckPromoCode, CheckPromoCodeVariables>(checkPromoCodeGql, {
      input: { code: "" },
    });

  const checkPromoCode = async (input: CheckPromoCodeInput) => {
    const response = await refetchCheckPromoCode({ input });

    const parsedResponse =
      parseGqlResponse<CheckPromoCode_checkPromoCode_PromoCode>(
        "PromoCode",
        response,
        options.hideErrors ? HideIfErrorCode.ALL_ERRORS : null
      );

    if (parsedResponse.error) {
      return parsedResponse.error;
    }

    return parsedResponse.data;
  };

  return {
    checkPromoCodeLoading,
    checkPromoCode,
  };
};
