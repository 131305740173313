import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "mb-8" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "my-14" }
const _hoisted_8 = {
  key: 0,
  class: "pl-4 mb-0"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "pl-4" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { class: "pl-4" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "my-14" }
const _hoisted_17 = {
  key: 0,
  class: "quotation-summary-card__next-steps-message__title"
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_DiscountedPrice = _resolveComponent("DiscountedPrice")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_QuotationPromoCode = _resolveComponent("QuotationPromoCode")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "quotation-summary-card p-6 md:p-10" }, {
    default: _withCtx(() => [
      (_ctx.loading || _ctx.quotationPricesLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: "",
            width: "100%"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_typography_title, {
                level: 3,
                class: "text-center mb-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Order Summary")), 1)
                ]),
                _: 1
              }),
              (!_ctx.hideOwner)
                ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                    key: 0,
                    class: "quotation-summary-card__owner text-center mb-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_i18n_t, { keypath: "{email}{order}" }, _createSlots({
                        email: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.quotation?.user?.username), 1)
                        ]),
                        _: 2
                      }, [
                        (_ctx.quotation?.order?.id)
                          ? {
                              name: "order",
                              fn: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.t(", ")), 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.t("Order #{id}", {
                  id: _ctx.quotation.order.id,
                })), 1)
                              ])
                            }
                          : undefined
                      ]), 1024)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_typography_title, {
                level: 5,
                class: "uppercase tracking-wide m-0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.quotation?.useTestimonialPackage
              ? _ctx.t("Testimonial Package")
              : _ctx.t("Basic Package")), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_a_row, {
              align: "bottom",
              gutter: [0, 16],
              class: "my-10"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 24,
                  md: 10
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_title, {
                      level: 4,
                      class: "m-0"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("{videos}, {creators}", {
                videos: _ctx.t("video", _ctx.quotation?.numOfVideos ?? 0),
                creators: _ctx.t("model creator", _ctx.quotation?.numOfPerformers ?? 0),
              })), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 12,
                  md: 10
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DiscountedPrice, {
                      "original-price": _ctx.originalPricePerVideo,
                      "current-price": _ctx.discountedPricePerVideo,
                      class: "font-bold text-2xl"
                    }, null, 8, ["original-price", "current-price"]),
                    _createVNode(_component_a_typography_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t(" / video")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 12,
                  md: 4,
                  class: "text-right"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DiscountedPrice, {
                      "original-price": _ctx.totalOriginalPriceAllVideosNoAddOns,
                      "current-price": _ctx.totalPriceAllVideosNoAddOns,
                      class: "font-bold text-2xl"
                    }, null, 8, ["original-price", "current-price"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_a_typography_title, { level: 6 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Add-ons")), 1)
                ]),
                _: 1
              }),
              (_ctx.filteredAddOnsList.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredAddOnsList, (addOn) => {
                    return (_openBlock(), _createBlock(_component_a_row, {
                      key: addOn.key,
                      class: "my-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, {
                          span: 24,
                          md: 10,
                          class: "font-semibold"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(addOn.label), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_col, {
                          span: 12,
                          md: 10
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.n(addOn.price, "currency", "ph") + _ctx.t(" / ")) + " " + _toDisplayString(addOn.priceUnit || addOn.multiplier || _ctx.t("video")), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_col, {
                          span: 12,
                          md: 4,
                          class: "text-right"
                        }, {
                          default: _withCtx(() => [
                            (addOn.multiplier === 'video')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.n(
                    addOn.price * (_ctx.quotation?.numOfVideos ?? 0),
                    "currency",
                    "ph"
                  )), 1))
                              : (addOn.multiplier === 'creator')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.n(
                    addOn.price * (_ctx.quotation?.numOfPerformers ?? 0),
                    "currency",
                    "ph"
                  )), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.n(addOn.price, "currency", "ph")), 1))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                : (_openBlock(), _createBlock(_component_a_typography_text, {
                    key: 1,
                    class: "font-semibold"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("None")), 1)
                    ]),
                    _: 1
                  }))
            ]),
            _createVNode(_component_a_row, {
              align: "bottom",
              gutter: [32, 32]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 24,
                  md: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_title, { level: 5 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Promo Code")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_QuotationPromoCode, {
                      "current-promo-code": _ctx.quotation?.promoCode,
                      loading: _ctx.promoCodeLoading,
                      disabled: !!_ctx.quotation?.order || _ctx.promoCodeDisabled,
                      onOnUpdate: _cache[0] || (_cache[0] = (promoCode) => _ctx.$emit('on-update-promo-code', promoCode))
                    }, null, 8, ["current-promo-code", "loading", "disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  md: 12,
                  class: "text-right"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_text, { class: "text-2xl font-semibold uppercase tracking-wider mr-2" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.hasDiscount ? _ctx.t("Discounted Total") : _ctx.t("Total")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_DiscountedPrice, {
                      "original-price": _ctx.totalPriceWithoutDiscount,
                      "current-price": _ctx.totalPrice,
                      class: "font-bold text-3xl"
                    }, null, 8, ["original-price", "current-price"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_a_typography_title, { level: 5 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Terms and Conditions")), 1)
                ]),
                _: 1
              }),
              (_ctx.generalTermsList.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generalTermsList, (term) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: term,
                        class: "leading-relaxed",
                        innerHTML: term
                      }, null, 8, _hoisted_9))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hasPromo)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_ctx.showTermTitles)
                      ? (_openBlock(), _createBlock(_component_a_typography_title, {
                          key: 0,
                          level: 6,
                          class: "mt-4"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Promo Code")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("ul", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promoCodeTerms, (term) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: term,
                          class: "leading-relaxed",
                          innerHTML: term
                        }, null, 8, _hoisted_12))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.quotation?.useTestimonialPackage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (_ctx.showTermTitles)
                      ? (_openBlock(), _createBlock(_component_a_typography_title, {
                          key: 0,
                          level: 6,
                          class: "mt-4"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Testimonial Package")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("ul", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.testimonialTermsFullList, (term) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: term,
                          class: "leading-relaxed",
                          innerHTML: term
                        }, null, 8, _hoisted_15))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_a_typography_title, { level: 5 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Next Step")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_typography_paragraph, { class: "quotation-summary-card__next-steps-message" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t(
              "We will email you the instructions to settle the payment. Reach out to us for any concerns or requests. If you have remaining Video Credits, you may skip the payment. We will move your order forward accordingly. Thank you!"
            )), 1)
                ]),
                _: 1
              }),
              (_ctx.additionalNotes.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.additionalNotes, (additionalNote) => {
                    return (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                      key: additionalNote.key,
                      class: "quotation-summary-card__next-steps-message"
                    }, {
                      default: _withCtx(() => [
                        (additionalNote.title)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(additionalNote.title), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          innerHTML: additionalNote.content
                        }, null, 8, _hoisted_18)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_19, [
              (!_ctx.quotation?.order)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    type: "primary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mainButtonProperties.handler())),
                    class: "quotation-summary-card__proceed-button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.mainButtonProperties.label), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    type: "primary",
                    onClick: _ctx.redirectToOrderDetail,
                    class: "quotation-summary-card__proceed-button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Go to Order Details")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
            ])
          ]))
    ]),
    _: 1
  }))
}